/* global enquire, _THEME, _BASEURI */

function themeRedirectUri(current, required) {
    
    if( current !== required ) {
        var url = window.location.pathname;
        window.location.href = _BASEURI + '/theme/' + required +  url;
    }
    
}

enquire.register('screen and (max-width : 991px)', {
    match: function () {
        themeRedirectUri(_THEME, 'default');
    }
});
enquire.register('screen and (min-width : 992px)', {
    match: function () {
        themeRedirectUri(_THEME, 'asian');
    }
});

